import React from 'react'
import { ResponsivePie } from '@nivo/pie'

function Pie(props) {
	return (
		<div style={{ height: 600, width: props.show ? '50%' : '100%', padding: 20 }}>
			{props.trafficSource && props.trafficSource.length > 0 && (
				<ResponsivePie
					tooltip={(val) => (
						<div
							style={{
								background: '#fff',
								padding: 5,
								display: 'flex',
								justifyContent: 'space-between',
								flexDirection: 'column',
								alignItems: 'flex-start',
							}}
						>
							<p style={{ margin: '0 10px' }}>
								<b>{val.datum.id}</b>: {val.datum.value}%
							</p>
						</div>
					)}
					sliceLabel={(item) => `${item.value}%`}
					data={props.trafficSource}
					margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
					padAngle={0.7}
					cornerRadius={3}
					colors={{ scheme: 'category10' }}
					borderWidth={1}
					borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
					radialLabelsSkipAngle={10}
					radialLabelsTextColor='#333333'
					radialLabelsLinkColor={{ from: 'color' }}
					sliceLabelsSkipAngle={10}
					sliceLabelsTextColor='#ffffff'
					defs={[
						{
							id: 'dots',
							type: 'patternDots',
							background: 'inherit',
							color: 'rgba(255, 255, 255, 0.3)',
							size: 4,
							padding: 1,
							stagger: true,
						},
						{
							id: 'lines',
							type: 'patternLines',
							background: 'inherit',
							color: 'rgba(255, 255, 255, 0.3)',
							rotation: -45,
							lineWidth: 6,
							spacing: 10,
						},
					]}
					legends={[]}
				/>
			)}
		</div>
	)
}

export default Pie
