import React from 'react'
import { ResponsiveLine } from '@nivo/line'
import { Radio } from 'antd'

function Line({ data, onChange, value }) {
	const plainOptions = [
		{ label: 'Nombre de pages vues', value: 'Pageviews' },
		{ label: 'Nouveaux utilisateurs', value: 'NewUsers' },
		{ label: 'Utilisateurs', value: 'Users' },
		{ label: 'Sessions', value: 'Sessions' },
	]

	return (
		<div style={{ height: 700, width: '100%', padding: 20 }}>
			<Radio.Group onChange={onChange} value={value}>
				{plainOptions.map((el) => (
					<Radio value={el.value}>{el.label}</Radio>
				))}
			</Radio.Group>
			{data && (
				<ResponsiveLine
					groupMode='grouped'
					data={data}
					margin={{ top: 50, right: 110, bottom: 80, left: 60 }}
					xScale={{ type: 'point' }}
					yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false }}
					axisTop={null}
					axisRight={null}
					axisBottom={{
						orient: 'bottom',
						tickSize: 5,
						tickRotation: -60,
						legend: ' ',
						legendOffset: 36,
						legendPosition: 'middle',
						orient: 'bottom',
						tickPadding: 10,
						tickValues: 10,
						gridXValues: 10,
					}}
					axisLeft={{
						orient: 'left',
						tickSize: 5,
						tickPadding: 5,
						tickRotation: 0,
						legend: '',
						legendOffset: -50,
						legendPosition: 'middle',
					}}
					colors={{ scheme: 'category10' }}
					pointSize={7}
					pointColor={{ theme: 'background' }}
					pointBorderWidth={2}
					pointBorderColor={{ from: 'serieColor' }}
					pointLabelYOffset={-12}
					useMesh={true}
					tooltip={(val) => (
						<div
							style={{
								background: '#fff',
								padding: 5,
								display: 'flex',
								justifyContent: 'space-between',
								boxShadow: '0 0 10px #333',
								flexDirection: 'column',
								alignItems: 'flex-start',
							}}
						>
							<p style={{ margin: '0 10px' }}>
								<b>Date:</b> {val.point.data.x}
							</p>
							<p style={{ margin: '0 10px' }}>
								<b>{val.point.id.split(' ')[0]}:</b> {val.point.data.y}
							</p>
						</div>
					)}
					legends={[
						{
							anchor: 'bottom-right',
							direction: 'column',
							justify: false,
							translateX: 100,
							translateY: 0,
							itemsSpacing: 0,
							itemDirection: 'left-to-right',
							itemWidth: 80,
							itemHeight: 20,
							itemOpacity: 0.75,
							symbolSize: 12,
							symbolShape: 'circle',
							symbolBorderColor: 'rgba(0, 0, 0, .5)',
							effects: [
								{
									on: 'hover',
									style: {
										itemBackground: 'rgba(0, 0, 0, .03)',
										itemOpacity: 1,
									},
								},
							],
						},
					]}
				/>
			)}
		</div>
	)
}

export default Line
