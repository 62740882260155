import React, { useEffect, useState } from 'react'
import { ResponsiveBar } from '@nivo/bar'
import _ from 'loadsh'

function Pie(props) {
	const [data, setData] = useState(null)
	const [male, setmale] = useState(null)
	const [female, setfemale] = useState(null)
	useEffect(() => {
		if (props.usersagegender && props.usersagegender.length > 0) {
			setmale(
				Object.values(props.usersagegender.find((el) => el.id == 'male'))
					.filter((el) => el != 'male')
					.reduce((acc, el) => acc + el, 0)
			)
			setfemale(
				Object.values(props.usersagegender.find((el) => el.id == 'female'))
					.filter((el) => el != 'female')
					.reduce((acc, el) => acc + el, 0)
			)
			console.log(
				Object.values(props.usersagegender.find((el) => el.id == 'male'))
					.filter((el) => el != 'male')
					.reduce((acc, el) => acc + el, 0)
			)
			console.log('origin ', props.usersagegender)
			setData(
				props.usersagegender.map((el) => ({
					...el,
					id: el.id == 'female' ? 'femme' : 'homme',
				}))
			)
		}
	}, [props.usersagegender])

	useEffect(() => {
		console.log(data)
	}, [data])
	return (
		<div style={{ height: 600, width: props.show ? '50%' : '100%', padding: 20 }}>
			{data && data.length > 0 && (
				<ResponsiveBar
					tooltip={(val) => (
						<div
							style={{
								background: '#fff',
								padding: 5,
								display: 'flex',
								justifyContent: 'space-between',
								flexDirection: 'column',
								alignItems: 'flex-start',
							}}
						>
							<p style={{ margin: '0 10px' }}>
								<b>
									{val.id.includes('female')
										? val.id.replace('female', 'femme')
										: val.id.replace('male', 'homme')}
								</b>
								: {val.value}
							</p>
						</div>
					)}
					data={data}
					keys={_.flatten(
						data.map((el) => Object.keys(el).filter((Element) => Element != 'id'))
					)}
					indexBy='id'
					margin={{ top: 50, right: 100, bottom: 50, left: 100 }}
					padding={0.3}
					layout='vertical'
					valueScale={{ type: 'linear' }}
					indexScale={{ type: 'band', round: true }}
					colors={{ scheme: 'category10' }}
					label={(d) =>
						`${d.id
							.replace('female', '')
							.replace('male', '')
							.replace(')', '')
							.replace('(', '')} ans: ${Math.floor(
							(d.value * 100) / (d.id.includes('female') ? female : male)
						)}%`
					}
					defs={[
						{
							id: 'dots',
							type: 'patternDots',
							background: 'inherit',
							color: '#38bcb2',
							size: 4,
							padding: 1,
							stagger: true,
						},
						{
							id: 'lines',
							type: 'patternLines',
							background: 'inherit',
							color: '#eed312',
							rotation: -45,
							lineWidth: 6,
							spacing: 10,
						},
					]}
					borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
					axisTop={null}
					axisRight={null}
					axisBottom={{
						tickSize: 5,
						tickPadding: 5,
						tickRotation: 0,
						legend: 'traffics',
						legendPosition: 'middle',
						legendOffset: 32,
					}}
					axisLeft={{
						tickSize: 5,
						tickPadding: 5,
						tickRotation: 0,
						legend: '',
						legendPosition: 'middle',
						legendOffset: -70,
						dotLabelFormat: (v) => `${v}%`,
					}}
					labelSkipWidth={12}
					labelSkipHeight={12}
					labelTextColor='#ffffff'
					legends={[]}
					animate={true}
					motionStiffness={90}
					motionDamping={15}
				/>
			)}
		</div>
	)
}

export default Pie
