import { rootpageApi } from "../config";
import axios from 'axios'

export const fetchAccount= (all) => {
    return (dispatch) => {
        return axios.post(rootpageApi,{
            "all":all
        }).then((res) => {
            dispatch({
                type: 'ACCOUNT',
                payload: res.data
            })
          }); 
    }
}

