import React from 'react'
import OrderSell from '../../components/OrderSell'
import PieceSold from '../../components/PieceSold'
import Products from '../../components/Products'
import TotalVolume from '../../components/TotalVolume'
import QuantityProducts from '../../components/QuantityProducts'
import SellPrice from '../../components/SellsPrice'

function ProductsPage({
	accounts,
	date,
	fetcher,
	setLoader,
	productCount,
	viewProducts,
	totalVolume,
	sellTime,
	netAffair,
	orders,
	multipleState,
}) {
	return (
		<>
			{viewProducts && (
				<Products
					accounts={accounts}
					date={date}
					fetcher={fetcher}
					setLoader={setLoader}
					viewProducts={viewProducts}
				/>
			)}
			{totalVolume && (
				<TotalVolume
					accounts={accounts}
					date={date}
					fetcher={fetcher}
					setLoader={setLoader}
					totalVolume={totalVolume}
				/>
			)}
			{orders && (
				<PieceSold
					accounts={accounts}
					date={date}
					fetcher={fetcher}
					setLoader={setLoader}
					orders={orders}
					multipleState={multipleState}
				/>
			)}
			{sellTime && (
				<OrderSell
					accounts={accounts}
					date={date}
					fetcher={fetcher}
					setLoader={setLoader}
					sellTime={sellTime}
				/>
			)}
			{productCount && (
				<QuantityProducts
					accounts={accounts}
					date={date}
					fetcher={fetcher}
					setLoader={setLoader}
					productCount={productCount}
					multipleState={multipleState}
				/>
			)}
			{netAffair && (
				<SellPrice
					accounts={accounts}
					date={date}
					fetcher={fetcher}
					setLoader={setLoader}
					netAffair={netAffair}
					multipleState={multipleState}
				/>
			)}
		</>
	)
}

export default ProductsPage
