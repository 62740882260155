import React from 'react'

import { Card, Row, Col } from 'antd'
import Layout, { Content } from 'antd/lib/layout/layout'

function TotalVolume({ totalVolume }) {
	return (
		<Layout className='site-layout'>
			<Content
				className='site-layout-background'
				style={{
					margin: '24px 16px 24px 16px',
					padding: 24,
				}}
			>
				{totalVolume ? (
					<div style={{ width: '100%' }}>
						<div style={{ textAlign: 'center', padding: 13, marginTop: 30 }}>
							<Row>
								<Col style={{ flex: 1 }}>
									<Card style={{ height: 126 }}>
										<h2 style={{ position: 'relative' }}>
											Nombre total des commandes
										</h2>

										<p>{totalVolume.firstRange}</p>
									</Card>
								</Col>
							</Row>
						</div>
					</div>
				) : (
					<span>Loading</span>
				)}
			</Content>
		</Layout>
	)
}

export default TotalVolume
