import { subDays } from 'date-fns'

//export const rootpageApi="https://analytics-std.herokuapp.com"
export const rootpageApi = 'https://young-brushlands-46824.herokuapp.com'
export const defaultDate = [subDays(new Date(), 60), subDays(new Date(), 30)]
export const domain_from_url = (url) => {
	var result
	var match
	if ((match = url.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im))) {
		result = match[1]
		if ((match = result.match(/^[^\.]$/))) {
			result = match[1]
		}
	}
	return result
}
