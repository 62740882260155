import React from 'react'
import SourceTraffic from '../../components/SourceTraffic'
import TrafficPeriode from '../../components/TrafficPeriode'
import TopUrlViews from '../../components/TopUrlViews'
import TotalData from '../TotalData'
import Gender from '../../components/Gender'
import Location from '../../components/Location'
import BounceRatewm from '../../components/BounceRatewm'
import { Row, Col, Layout } from 'antd'

function Dashboard({
	data,
	state,
	accountVal,
	fetcher,
	setLoader,
	multipleState,
	totalValues,
	topUrlsViews,
	TrafficSource,
	TrafficPeriod,
	usersagegender,
	bounceRateMobileWeb,
	geolocation,
}) {
	const { Header, Sider, Content } = Layout
	return (
		<>
			<Content
				className='site-layout-background'
				style={{
					margin: '74px 16px 24px 16px',
					padding: 24,
					minHeight: 280,
					width: '80%',
					marginLeft: 200,
				}}
			>
				<>
					<TotalData
						accounts={data}
						date={state}
						accountVal={accountVal}
						fetcher={fetcher}
						setLoader={setLoader}
						totalValues={totalValues}
						multipleState={multipleState}
					/>
				</>
			</Content>
			<Content
				className='site-layout-background'
				style={{
					margin: '24px 16px',
					padding: 24,
					minHeight: 280,
					width: '80%',
					marginLeft: 200,
				}}
			>
				<>
					<TopUrlViews
						accounts={data}
						date={state}
						accountVal={accountVal}
						fetcher={fetcher}
						setLoader={setLoader}
						topUrlsViewsData={topUrlsViews}
						multipleState={multipleState}
					/>
				</>
			</Content>
			<Row>
				<Col span={24}>
					<Content
						className='site-layout-background'
						style={{
							margin: '24px 16px',
							padding: 24,
							minHeight: 280,
							width: '80%',
							marginLeft: 200,
						}}
					>
						<>
							<SourceTraffic
								accounts={data}
								date={state}
								accountVal={accountVal}
								fetcher={fetcher}
								setLoader={setLoader}
								trafficSource={TrafficSource}
								multipleState={multipleState}
							/>
						</>
					</Content>
				</Col>
			</Row>
			<Content
				className='site-layout-background'
				style={{
					margin: '24px 16px',
					padding: 24,
					minHeight: 280,
					width: '80%',
					marginLeft: 200,
				}}
			>
				<>
					<TrafficPeriode
						accounts={data}
						date={state}
						accountVal={accountVal}
						fetcher={fetcher}
						setLoader={setLoader}
						multipleState={multipleState}
						trafficPeriod={TrafficPeriod}
					/>
				</>
			</Content>

			<Row>
				<Col span={24}>
					<Content
						className='site-layout-background'
						style={{
							margin: '24px 16px',
							padding: 24,
							minHeight: 280,
							width: '80%',
							marginLeft: 200,
						}}
					>
						<>
							<Gender
								accounts={data}
								date={state}
								accountVal={accountVal}
								fetcher={fetcher}
								setLoader={setLoader}
								usersagegender={usersagegender}
								multipleState={multipleState}
							/>
						</>
					</Content>
				</Col>
			</Row>
			<Content
				className='site-layout-background'
				style={{
					margin: '24px 16px',
					padding: 24,
					minHeight: 280,
					width: '80%',
					marginLeft: 200,
				}}
			>
				<>
					<BounceRatewm
						accounts={data}
						date={state}
						accountVal={accountVal}
						fetcher={fetcher}
						setLoader={setLoader}
						bounceRateMobileWeb={bounceRateMobileWeb}
						multipleState={multipleState}
					/>
				</>
			</Content>
			<Content
				className='site-layout-background'
				style={{
					margin: '24px 16px',
					padding: 24,
					minHeight: 280,
					width: '80%',
					marginLeft: 200,
				}}
			>
				<>
					<Location
						accounts={data}
						date={state}
						accountVal={accountVal}
						fetcher={fetcher}
						setLoader={setLoader}
						multipleState={multipleState}
						location={geolocation}
					/>
				</>
			</Content>
		</>
	)
}

export default Dashboard
