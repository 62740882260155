import React, { useState } from 'react'
import { ResponsiveBar } from '@nivo/bar'
import Map from '../Map'

function Bar({ data, show }) {
	const [height, setHeight] = useState(600)

	return (
		<div style={{ width: show ? '45%' : '100%', margin: show ? '0 2.5%' : 0 }}>
			<div style={{ height: height }}>
				<p style={{ float: 'left', transform: 'translateY(-10px)' }}>
					Sur la base de {data.reduce((acc, el) => acc + el.usersCount, 0)} utilisateurs
				</p>
				<ResponsiveBar
					tooltip={(val) => (
						<div
							style={{
								background: '#fff',
								padding: 5,
								display: 'flex',
								justifyContent: 'space-between',
								flexDirection: 'column',
								alignItems: 'flex-start',
							}}
						>
							<span>
								Nombre utilisateurs -{' '}
								{data.find((el) => el.usersCount == val.value)._id} {val.value} ({' '}
								{(
									(val.value * 100) /
									data.reduce((acc, el) => acc + el.usersCount, 0)
								).toFixed(2)}
								% )
							</span>
						</div>
					)}
					data={data}
					keys={['usersCount']}
					indexBy='_id'
					margin={{ top: 50, right: 130, bottom: 50, left: 150 }}
					padding={0.3}
					layout='horizontal'
					valueScale={{ type: 'linear' }}
					indexScale={{ type: 'band', round: true }}
					colors={{ scheme: 'category10' }}
					defs={[
						{
							id: 'dots',
							type: 'patternDots',
							background: 'inherit',
							color: '#38bcb2',
							size: 4,
							padding: 1,
							stagger: true,
						},
						{
							id: 'lines',
							type: 'patternLines',
							background: 'inherit',
							color: '#eed312',
							rotation: -45,
							lineWidth: 6,
							spacing: 10,
						},
					]}
					borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
					axisTop={null}
					axisRight={null}
					axisBottom={{
						tickSize: 5,
						tickPadding: 5,
						tickRotation: 0,
						legend: 'Users',
						legendPosition: 'middle',
						legendOffset: 32,
					}}
					axisLeft={{
						tickSize: 5,
						tickPadding: 5,
						tickRotation: 0,
						legend: '',
						legendPosition: 'middle',
						legendOffset: -50,
					}}
					labelSkipWidth={12}
					labelSkipHeight={12}
					labelTextColor='#fff'
					legends={[
						{
							dataFrom: 'keys',
							anchor: 'bottom-right',
							direction: 'column',
							justify: false,
							translateX: 120,
							translateY: 0,
							itemsSpacing: 2,
							itemWidth: 100,
							itemHeight: 20,
							itemDirection: 'left-to-right',
							itemOpacity: 0.85,
							symbolSize: 20,
							effects: [
								{
									on: 'hover',
									style: {
										itemOpacity: 1,
									},
								},
							],
						},
					]}
					animate={true}
					motionStiffness={90}
					motionDamping={15}
				/>
			</div>
			<Map data={data} />
		</div>
	)
}

export default Bar
