import React, { useEffect, useState } from 'react'
import { ResponsiveLine } from '@nivo/line'
import Layout, { Content } from 'antd/lib/layout/layout'

function PieceSold(props) {
	const [data, setData] = useState(null)
	useEffect(() => {
		if (props.productCount?.hasOwnProperty('secondRange') && props.multipleState.compare) {
			setData([...props.productCount.secondRange, ...props.productCount.firstRange])
		} else {
			setData(props.productCount?.firstRange)
		}
		console.log('topurl ', props.productCount)
	}, [props.productCount, props.multipleState.compare, props.productCount?.firstRange])

	return (
		<Layout className='site-layout'>
			<Content
				className='site-layout-background'
				style={{
					margin: '24px 16px 24px 16px',
					padding: 24,
				}}
			>
				<h2>Variation du nombre des produits vendus par jour</h2>
				<div style={{ height: 700, width: '100%', paddingBottom: 20 }}>
					{data && (
						<ResponsiveLine
							groupMode='grouped'
							data={data}
							margin={{ top: 50, right: 110, bottom: 100, left: 60 }}
							xScale={{ type: 'point' }}
							yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false }}
							// yFormat=" >-.2f"
							axisTop={null}
							axisRight={null}
							tooltip={(val) => (
								<div
									style={{
										background: '#fff',
										padding: 5,
										display: 'flex',
										justifyContent: 'space-between',
										boxShadow: '0 0 10px #333',
										flexDirection: 'column',
										alignItems: 'flex-start',
									}}
								>
									<p style={{ margin: '0 10px' }}>
										<b>Date:</b> {val.point.data.x}
									</p>
									<p style={{ margin: '0 10px' }}>
										<b>{val.point.id.split(' ')[0]}:</b> {val.point.data.y}
									</p>
								</div>
							)}
							axisBottom={{
								orient: 'bottom',
								tickSize: 5,
								tickRotation: 0,
								legend: ' ',
								legendOffset: 36,
								legendPosition: 'middle',
								orient: 'bottom',
								tickPadding: 5,
								tickRotation: 60,
								tickValues: 10,
							}}
							axisLeft={{
								orient: 'left',
								tickSize: 5,
								tickPadding: 5,
								tickRotation: 0,
								legend: 'products',
								legendOffset: -50,
								legendPosition: 'middle',
							}}
							colors={{ scheme: 'category10' }}
							pointSize={7}
							pointColor={{ theme: 'background' }}
							pointBorderWidth={2}
							pointBorderColor={{ from: 'serieColor' }}
							pointLabelYOffset={-12}
							useMesh={true}
							legends={[
								{
									anchor: 'bottom-right',
									direction: 'column',
									justify: false,
									translateX: 100,
									translateY: 0,
									itemsSpacing: 0,
									itemDirection: 'left-to-right',
									itemWidth: 80,
									itemHeight: 20,
									itemOpacity: 0.75,
									symbolSize: 12,
									symbolShape: 'circle',
									symbolBorderColor: 'rgba(0, 0, 0, .5)',
									effects: [
										{
											on: 'hover',
											style: {
												itemBackground: 'rgba(0, 0, 0, .03)',
												itemOpacity: 1,
											},
										},
									],
								},
							]}
						/>
					)}
				</div>
			</Content>
		</Layout>
	)
}

export default PieceSold
