import { useEffect, useState } from 'react'
import { Row, Col, Button, Card, Typography, Avatar, Badge } from 'antd'
import axios from 'axios'
import { rootpageApi } from '../../config'

import { fetchAccount } from '../../actions/allAccount'
import { useDispatch } from 'react-redux'
import { UserOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'

const Accounts = (props) => {
	const history = useHistory()
	const [data, setData] = useState(null)
	const [accountChecked, setAccountChecked] = useState([])
	const [loader, setLoader] = useState(null)
	const dispatch = useDispatch()

	useEffect(() => {
		axios
			.post(rootpageApi, {
				all: true,
			})
			.then((res) => {
				setData(res.data)
				setAccountChecked(res.data.filter((el) => el.active == true).map((el) => el._id))
			})
	}, [])

	function handleClick(id) {
		if (accountChecked.includes(id)) {
			setAccountChecked(accountChecked.filter((el) => el != id))
		} else {
			setAccountChecked([...accountChecked, id])
		}
	}

	return (
		<>
			{data && (
				<>
					<div
						style={{
							height: '100vh',
							width: '80%',
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							marginLeft: '220px',
						}}
					>
						<Typography.Title
							level={3}
							style={{ marginTop: '32px', textAlign: 'left' }}
						>
							Gestion des comptes
						</Typography.Title>
						<Row
							style={{
								display: 'flex',
								justifyContent: 'center',
								background: 'white',
								width: '100%',
								marginTop: '64px',

								padding: '0 10%',
							}}
							justify='start'
						>
							{data &&
								data.map((el) => (
									<Col
										span={4}
										style={{
											margin: '16px 16px 16px 16px',
											display: 'flex',
											alignItems: 'center',
										}}
										xs={24}
										sm={24}
										md={10}
										lg={6}
										key={el._id}
									>
										<Card
											bordered
											style={{
												borderWidth: '1px',
												borderRadius: '4%',
												padding: '4px 4px',
												minHeight: '150px',
												width: '100%',
												boxShadow:
													'0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 4px 8px 0 rgba(0, 0, 0, 0.15)',
											}}
										>
											<Row justify='space-between' wrap={false}>
												<Typography.Title level={5}>
													{el.account}
												</Typography.Title>
												{accountChecked.includes(el._id) ? (
													<Badge dot />
												) : null}
											</Row>
											<Row justify='center'>
												{' '}
												<Avatar
													size='large'
													shape='circle'
													icon={<UserOutlined />}
												/>
											</Row>
											<Row style={{ marginTop: '8px' }} justify='center'>
												<Button
													onClick={() => {
														handleClick(el._id)
													}}
												>
													{accountChecked.includes(el._id)
														? 'Désactiver'
														: 'Activer'}
												</Button>{' '}
											</Row>
										</Card>
									</Col>
								))}
						</Row>
						<Button
							type=''
							onClick={() => {
								setLoader(true)
								axios
									.post(rootpageApi + '/accountsState', {
										data: accountChecked,
									})
									.then((res) => {
										console.log(res.data)
										dispatch(fetchAccount(false))
										setLoader(false)
										history.push('/accounts')
									})
							}}
							disabled={loader}
							style={{
								width: 120,

								marginTop: '64px',
							}}
						>
							Enregistrer
						</Button>
					</div>
				</>
			)}
		</>
	)
}

export default Accounts
