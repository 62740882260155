import React, { useState } from 'react'
import { Form, Input, Button } from 'antd'
import { Redirect, useHistory } from 'react-router-dom'

const Login = () => {
	const [username, setUsername] = useState('')
	const [pass, setPass] = useState('')
	const history = useHistory()
	const onFinish = (values) => {
		if (username == 'indata@gmail.com' && pass == 'placeholder') {
			localStorage.setItem('login', true)
			history.push('dashboard')
		} else {
			alert('check values')
		}
	}

	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo)
	}

	return (
		<>
			{!localStorage.getItem('login') ? (
				<div style={{ height: '100vh', display: 'flex' }}>
					<div style={{ width: 400, margin: 'auto' }}>
						<Form
							name='basic'
							initialValues={{
								remember: true,
							}}
							onFinish={onFinish}
							onFinishFailed={onFinishFailed}
						>
							<Form.Item
								value={username}
								onChange={(e) => setUsername(e.target.value)}
								label='username'
								name='username'
								rules={[
									{
										required: true,
										message: 'Please input your email!',
									},
								]}
							>
								<Input />
							</Form.Item>

							<Form.Item
								value={pass}
								onChange={(e) => setPass(e.target.value)}
								label='Password'
								name='password'
								rules={[
									{
										required: true,
										message: 'Please input your password!',
									},
								]}
							>
								<Input.Password />
							</Form.Item>

							<Form.Item>
								<Button type='primary' htmlType='submit'>
									Submit
								</Button>
							</Form.Item>
						</Form>
					</div>
				</div>
			) : (
				<Redirect to='/dashboard' />
			)}
		</>
	)
}

export default Login
