import React from 'react'
import { Card } from 'antd'
import { Typography } from 'antd'

function TotalDataCard({
	loading,
	title,
	startDate,
	endDate,
	value,
	compare,
	compareStartDate,
	compareEndDate,
	compareValue,
}) {
	const { Title } = Typography
	return (
		<Card
			loading={loading}
			bordered
			style={{
				borderWidth: '1px',
				borderRadius: '4%',
				padding: '16px 4px',
				minHeight: '200px',
				width: '100%',
				boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 4px 8px 0 rgba(0, 0, 0, 0.15)',
			}}
		>
			<Title level={5} style={{ position: 'relative' }}>
				{title}
			</Title>
			<p>{startDate + ' - ' + endDate}</p>
			<p
				style={{
					color: 'orange',
					fontFamily: 'sans-serif',
					fontSize: '18px',
				}}
			>
				{value}
			</p>
			{compare ? (
				<p className='compare'>{compareStartDate + ' - ' + compareEndDate}</p>
			) : null}
			{compare && (
				<p
					style={{
						color: 'orange',
						fontFamily: 'sans-serif',
						fontSize: '18px',
					}}
				>
					{compareValue}
				</p>
			)}
		</Card>
	)
}

export default TotalDataCard
