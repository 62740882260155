import React from 'react'
import './App.css'
import Dashboard from './containers/Dashboard'
import ProductsPage from './containers/Products'
import Accounts from './containers/Accounts'
import { useEffect, useState } from 'react'
import { Button, Layout, Menu } from 'antd'
import { DateRangePicker } from 'react-date-range'
import fetchData from './fetchData'
import {
	MenuUnfoldOutlined,
	MenuFoldOutlined,
	UserOutlined,
	VideoCameraOutlined,
	UploadOutlined,
} from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { Select } from 'antd'
import moment from 'moment'
import { defaultDate, domain_from_url } from './config'
import { BrowserRouter as Router, Switch, Route, Link, useHistory } from 'react-router-dom'
import { addDays } from 'date-fns'
import { useLocation } from 'react-router-dom'
import { fetchAccount } from './actions/allAccount'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import '../node_modules/nprogress/nprogress.css'
import NProgress from 'nprogress'

const { Sider } = Layout
const { Option } = Select
function App() {
	const dispatch = useDispatch()
	const [state, setState] = useState(defaultDate)
	const [collapsed, setcollapsed] = useState(false)
	const [loader, setLoader] = useState(null)
	const [fetcher, setFetcher] = useState(0)
	const [check, setCheck] = useState(false)
	const [showPicker, setshowPicker] = useState(false)
	const data = useSelector((state) => state.accounts)
	const [account, setAccountData] = useState(null)
	const [topUrlsViews, settopUrlsViews] = useState(null)
	const [TrafficSource, setTrafficSource] = useState(null)
	const [TrafficPeriod, setTrafficPeriod] = useState(null)
	const [usersagegender, setusersagegender] = useState(null)
	const [bounceRateMobileWeb, setbounceRateMobileWeb] = useState(null)
	const [geolocation, setgeolocation] = useState(null)
	const [totalValues, settotalValues] = useState(null)
	const [productCount, setproductCount] = useState(null)
	const [viewProducts, setviewProducts] = useState(null)
	const [totalVolume, settotalVolume] = useState(null)
	const [sellTime, setsellTime] = useState(null)
	const [netAffair, setnetAffair] = useState(null)
	const [orders, setorders] = useState(null)

	const [accountVal, setAccount] = useState(data && data.length > 0 && data[0]._id)
	const item = {
		startDate: addDays(new Date(), -10),
		endDate: new Date(),
		key: 'compare',
	}
	const [multipleState, setmultipleState] = useState({
		selection: {
			startDate: addDays(new Date(), -30),
			endDate: addDays(new Date(), -0),
			key: 'selection',
		},
	})
	let location = useLocation()

	useEffect(() => {
		setcollapsed(false)
	}, [location])
	const handelClick = async (account) => {
		NProgress.start()

		setLoader(true)
		const topUrlsViews = await fetchData(
			'topUrlsViews',
			multipleState.hasOwnProperty('compare'),
			multipleState,
			account
		)
		const TrafficSource = await fetchData(
			'TrafficSource',
			multipleState.hasOwnProperty('compare'),
			multipleState,
			account
		)
		const trafficP = await fetchData(
			'TrafficPeriod',
			multipleState.hasOwnProperty('compare'),
			multipleState,
			account
		)
		const usersagegender = await fetchData(
			'usersagegender',
			multipleState.hasOwnProperty('compare'),
			multipleState,
			account
		)
		const bounceRateMobileWeb = await fetchData(
			'bounceRateMobileWeb',
			multipleState.hasOwnProperty('compare'),
			multipleState,
			account
		)
		const geolocation = await fetchData(
			'geolocation',
			multipleState.hasOwnProperty('compare'),
			multipleState,
			account
		)
		const totalVal = await fetchData(
			'totalValues',
			multipleState.hasOwnProperty('compare'),
			multipleState,
			account
		)
		settopUrlsViews(topUrlsViews)
		setTrafficSource(TrafficSource)
		setTrafficPeriod(trafficP)
		setusersagegender(usersagegender)
		setbounceRateMobileWeb(bounceRateMobileWeb)
		setgeolocation(geolocation)
		settotalValues(totalVal)
		setLoader(false)
		console.log(topUrlsViews)
		NProgress.done()
	}

	const handelClickProduct = async (account) => {
		NProgress.start()
		setLoader(true)
		const ordersData = await fetchData(
			'pieceSold',
			multipleState.hasOwnProperty('compare'),
			multipleState,
			account
		)
		const viewProductsData = await fetchData(
			'viewProducts',
			multipleState.hasOwnProperty('compare'),
			multipleState,
			account
		)
		const totalVolumeData = await fetchData(
			'totalVolume',
			multipleState.hasOwnProperty('compare'),
			multipleState,
			account
		)
		const sellTimeData = await fetchData(
			'sellTime',
			multipleState.hasOwnProperty('compare'),
			multipleState,
			account
		)

		const productCountData = await fetchData(
			'pieceSold',
			multipleState.hasOwnProperty('compare'),
			multipleState,
			account,
			'productCount'
		)
		const netAffairData = await fetchData(
			'pieceSold',
			multipleState.hasOwnProperty('compare'),
			multipleState,
			account,
			'sellTotalValue'
		)

		setviewProducts(viewProductsData)
		settotalVolume(totalVolumeData)
		setsellTime(sellTimeData)
		setproductCount(productCountData)
		setnetAffair(netAffairData)
		setorders(ordersData)
		setLoader(false)
		NProgress.done()
	}

	const history = useHistory()
	useEffect(() => {
		dispatch(fetchAccount(false))
	}, [])

	useEffect(() => {
		if (data && data.length > 0) {
			setAccountData(data[0]._id)
			setAccount(data[0]._id)
			console.log('dataaa ', data)
			handelClick(data[0]._id)
			handelClickProduct(data[0]._id)
		}
	}, [data])

	const toggleCollapsed = () => {
		setcollapsed(!collapsed)
	}
	useEffect(() => {
		const span = document.createElement('span')
		span.setAttribute(
			'style',
			'color:#000;display:flex;align-items:center;margin-right:5px;width: 70px;'
		)
		span.innerHTML = 'First date'
		if (showPicker) document.querySelectorAll('.compare .rdrDateDisplay')[0].prepend(span)
	}, [showPicker])

	useEffect(() => {
		if (document.querySelectorAll('.compare .rdrDateDisplay').length > 1) {
			const span2 = document.createElement('span')
			span2.setAttribute(
				'style',
				'color:#000;display:flex;align-items:center;margin-right:5px;width: 70px;'
			)
			span2.innerHTML = 'Second date'
			if (showPicker) document.querySelectorAll('.compare .rdrDateDisplay')[1].prepend(span2)
		}
	}, [check, showPicker])

	return (
		<Layout>
			<div className='sideMenu' style={{ left: collapsed ? 0 : '-200px' }}>
				<Sider trigger={null} style={{ background: 'white' }}>
					<div className='logo' />
					<Menu mode='inline' defaultSelectedKeys={['1']} style={{ background: 'white' }}>
						<Menu.Item key='1' icon={<UserOutlined />}>
							<Link to='/dashboard'>Acceuil</Link>
						</Menu.Item>
						<Menu.Item key='2' icon={<VideoCameraOutlined />}>
							<Link to='/products'>Produits</Link>
						</Menu.Item>
						<Menu.Item key='3' icon={<VideoCameraOutlined />}>
							<Link to='/accounts'>Comptes</Link>
						</Menu.Item>
						<Menu.Divider />
						<Menu.Item key='4' icon={<UploadOutlined />}>
							<Link
								onClick={() => {
									localStorage.removeItem('login')
									history.push('/')
								}}
							>
								Se déconnecter
							</Link>
						</Menu.Item>
					</Menu>
				</Sider>
			</div>
			<Button
				type='primary'
				onClick={toggleCollapsed}
				style={{
					marginBottom: 16,
					position: 'fixed',
					top: 20,
					bottom: 0,
					zIndex: 100,
					left: collapsed ? 230 : 20,
				}}
			>
				{React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined)}
			</Button>
			<div
				style={{
					position: 'fixed',
					top: 0,
					right: 0,
					zIndex: 99,
					margin: 20,
				}}
			>
				<div className='dateSelectorfixed'>
					{data && data.length > 0 && (
						<Select
							value={account}
							defaultValue={data[0]._id}
							style={{ width: 120, border: 'none' }}
							onChange={(val) => {
								console.log(val)
								setAccountData(val)
								if (location.pathname.includes('dashboard')) handelClick(val)
								else handelClickProduct(val)
							}}
						>
							{data.map((el) => (
								<Option value={el._id}>{domain_from_url(el.account)}</Option>
							))}
						</Select>
					)}
					<div style={{ width: 1, background: '#000', height: 35 }}></div>
					<div onClick={() => setshowPicker(!showPicker)}>
						<p>
							{moment(new Date(multipleState.selection.startDate)).format(
								'YYYY/MM/DD'
							)}{' '}
							-{' '}
							{moment(new Date(multipleState.selection.endDate)).format('YYYY/MM/DD')}
						</p>
						{multipleState.compare ? (
							<p className='compare'>
								{'Comparer à: ' +
									moment(new Date(multipleState.compare.startDate)).format(
										'YYYY/MM/DD'
									) +
									' - ' +
									moment(new Date(multipleState.compare.endDate)).format(
										'YYYY/MM/DD'
									)}
							</p>
						) : (
							''
						)}
					</div>
				</div>
				{showPicker && (
					<div
						style={{
							background: '#fff',
							boxShadow: '0 0 10px #ddd',
							marginTop: 10,
							display: 'inline-block',
						}}
					>
						<DateRangePicker
							showMonthAndYearPickers={false}
							onChange={(item) => {
								console.log('item ' + JSON.stringify(item))
								setmultipleState({ ...multipleState, ...item })
							}}
							months={1}
							minDate={addDays(new Date(), -300)}
							maxDate={new Date()}
							direction='vertical'
							scroll={{ enabled: true }}
							ranges={Object.keys(multipleState).map((el) => multipleState[el])}
							className='compare'
						/>
						<div style={{ padding: 10 }}>
							<Checkbox
								checked={check}
								onChange={(e) => {
									setmultipleState(
										e.target.checked
											? { ...multipleState, compare: item }
											: { selection: multipleState.selection }
									)
									setCheck(e.target.checked)
								}}
							>
								Compare
							</Checkbox>
							<Button
								htmlType='button'
								onClick={() => {
									setshowPicker(false)
									if (location.pathname.includes('dashboard'))
										handelClick(account)
									else handelClickProduct(account)
								}}
								disabled={loader}
							>
								submit
							</Button>
						</div>
					</div>
				)}
			</div>
			<Layout
				className='site-layout'
				onClick={() => {
					setcollapsed(false)
					setshowPicker(false)
				}}
			>
				<Switch>
					<Route path='/dashboard' exact>
						{data && data.length > 0 ? (
							<Dashboard
								data={data}
								state={state}
								accountVal={accountVal}
								fetcher={fetcher}
								setLoader={setLoader}
								multipleState={multipleState}
								totalValues={totalValues}
								topUrlsViews={topUrlsViews}
								TrafficSource={TrafficSource}
								TrafficPeriod={TrafficPeriod}
								usersagegender={usersagegender}
								bounceRateMobileWe={bounceRateMobileWeb}
								geolocation={geolocation}
							/>
						) : (
							<p>Loading...</p>
						)}
					</Route>
					<Route path='/products' exact>
						{data && data.length > 0 ? (
							<>
								<ProductsPage
									accounts={data}
									date={state}
									fetcher={fetcher}
									setLoader={setLoader}
									productCount={productCount}
									viewProducts={viewProducts}
									totalVolume={totalVolume}
									sellTime={sellTime}
									netAffair={netAffair}
									orders={orders}
									multipleState={multipleState}
								/>
							</>
						) : (
							<p>Loading...</p>
						)}
					</Route>
					<Route path='/accounts' exact>
						<Accounts account={account} />
					</Route>

					<Route>
						<p>404 page</p>
					</Route>
				</Switch>
			</Layout>
		</Layout>
	)
}

export default App
