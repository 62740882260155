import axios from 'axios'
import { rootpageApi } from './config'

export default async (path, compare, multipleState, account, modal = null) => {
	if (compare) {
		console.log('selection', JSON.stringify(multipleState))

		const firstRange = await axios.post(rootpageApi + '/' + path, {
			maxDate: multipleState.selection.endDate,
			minDate: multipleState.selection.startDate,
			id: account,
			number: 5,
			model: modal ? modal : ['Pageviews', 'NewUsers', 'Users', 'Sessions'],
		})

		const secondRange = await axios.post(rootpageApi + '/' + path, {
			maxDate: multipleState.compare.endDate,
			minDate: multipleState.compare.startDate,
			id: account,
			number: 5,
			model: modal ? modal : ['Pageviews', 'NewUsers', 'Users', 'Sessions'],
		})
		return { firstRange: firstRange.data, secondRange: secondRange.data }
	} else {
		console.log('selection', JSON.stringify(multipleState))
		const firstRange = await axios.post(rootpageApi + '/' + path, {
			maxDate: multipleState.selection.endDate,
			minDate: multipleState.selection.startDate,
			id: account,
			number: 5,
			model: modal ? modal : ['Pageviews', 'NewUsers', 'Users', 'Sessions'],
		})

		return { firstRange: firstRange.data }
	}
}
