import React from 'react'
import moment from 'moment'
import { Card, Row, Col } from 'antd'

function Bar(props) {
	return (
		<>
			{props.bounceRateMobileWeb?.firstRange ? (
				<div style={{ width: '100%' }}>
					<div style={{ textAlign: 'center', padding: 13, marginTop: 30 }}>
						<h2>Type d'appareil</h2>

						<Row>
							{props.bounceRateMobileWeb.firstRange.map((el, i) => (
								<Col style={{ flex: 1 }}>
									<Card>
										<h2 style={{ position: 'relative' }}>{el._id}</h2>
										<p style={{ fontWeight: 'bold' }}>
											{moment(
												new Date(props.multipleState.selection.startDate)
											).format('YYYY/MM/DD')}{' '}
											-{' '}
											{moment(
												new Date(props.multipleState.selection.endDate)
											).format('YYYY/MM/DD')}
										</p>
										<p>bounceRate: {el.bounceRate}%</p>
										<p>users: {el.users}</p>
										<p>bounceRateNumber: {el.bounceRateNumber}</p>
										{props.bounceRateMobileWeb.secondRange &&
										props.multipleState.compare ? (
											<>
												<p
													className='compare'
													style={{ fontWeight: 'bold' }}
												>
													{moment(
														new Date(
															props.multipleState.compare.startDate
														)
													).format('YYYY/MM/DD') +
														' - ' +
														moment(
															new Date(
																props.multipleState.compare.endDate
															)
														).format('YYYY/MM/DD')}
												</p>
												<p>
													bounceRate:
													{props.bounceRateMobileWeb.secondRange &&
														props.bounceRateMobileWeb.secondRange[i]
															?.bounceRate}
													%
												</p>
												<p>
													users:{' '}
													{props.bounceRateMobileWeb.secondRange &&
														props.bounceRateMobileWeb.secondRange[i]
															?.users}
												</p>
												<p>
													bounceRateNumber:{' '}
													{props.bounceRateMobileWeb.secondRange &&
														props.bounceRateMobileWeb.secondRange[i]
															?.bounceRateNumber}
												</p>
											</>
										) : (
											''
										)}
									</Card>
								</Col>
							))}
						</Row>
					</div>
				</div>
			) : (
				<span>Loading</span>
			)}
		</>
	)
}

export default Bar
