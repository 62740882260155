import React from 'react'
import { useEffect, useState } from 'react'
import moment from 'moment'
import { Card, Row, Col, Typography } from 'antd'
import TotalDataCard from '../components/TotalDataCard'

function secondsToHms(d) {
	d = Number(d)
	var h = Math.floor(d / 3600)
	var m = Math.floor((d % 3600) / 60)
	var s = Math.floor((d % 3600) % 60)

	var hDisplay = h > 0 ? h + (h == 1 ? ' hour, ' : ' hours, ') : ''
	var mDisplay = m > 0 ? m + (m == 1 ? ' minute, ' : ' minutes, ') : ''
	var sDisplay = s > 0 ? s + (s == 1 ? ' second' : ' seconds') : ''
	return hDisplay + mDisplay + sDisplay
}
function Bar(props) {
	const [loading, setLoading] = useState(true)
	const { Title } = Typography

	useEffect(() => {
		if (props.totalValues) setLoading(false)
	}, [props.totalValues])

	console.log('loading', loading)
	return (
		<>
			<div style={{ width: '100%' }}>
				<div style={{ textAlign: 'center', marginTop: 30 }}>
					<Row justify='center'>
						<Col
							style={{ justifyContent: 'center', margin: '8px', display: 'flex' }}
							xs={24}
							sm={24}
							md={10}
							lg={5}
						>
							<TotalDataCard
								loading={loading}
								title={'Temps Moyen sur la page'}
								startDate={moment(
									new Date(props.multipleState.selection.startDate)
								).format('YYYY/MM/DD')}
								endDate={moment(
									new Date(props.multipleState.selection.endDate)
								).format('YYYY/MM/DD')}
								value={secondsToHms(
									Math.round(
										props.totalValues?.firstRange.reduce(
											(acc, el) => acc + el.timeOnPage,
											0
										) /
											props.totalValues?.firstRange.reduce(
												(acc, el) => acc + el.users,
												0
											)
									)
								)}
								compare={
									props.multipleState.compare && props.totalValues?.secondRange
								}
								compareStartDate={moment(
									new Date(props.multipleState.compare?.startDate)
								).format('YYYY/MM/DD')}
								compareEndDate={moment(
									new Date(props.multipleState.compare?.endDate)
								).format('YYYY/MM/DD')}
								compareValue={secondsToHms(
									Math.round(
										props.totalValues?.secondRange?.reduce(
											(acc, el) => acc + el.timeOnPage,
											0
										) /
											props.totalValues?.secondRange?.reduce(
												(acc, el) => acc + el.users,
												0
											)
									)
								)}
							/>{' '}
						</Col>
						<Col
							style={{ justifyContent: 'center', margin: '8px', display: 'flex' }}
							xs={24}
							sm={24}
							md={10}
							lg={5}
						>
							<TotalDataCard
								loading={loading}
								title={"Nombre Total d'utilisateurs"}
								startDate={moment(
									new Date(props.multipleState.selection.startDate)
								).format('YYYY/MM/DD')}
								endDate={moment(
									new Date(props.multipleState.selection.endDate)
								).format('YYYY/MM/DD')}
								value={props.totalValues?.firstRange.reduce(
									(acc, el) => acc + el.users,
									0
								)}
								compare={
									props.multipleState.compare && props.totalValues?.secondRange
								}
								compareStartDate={moment(
									new Date(props.multipleState.compare?.startDate)
								).format('YYYY/MM/DD')}
								compareEndDate={moment(
									new Date(props.multipleState.compare?.endDate)
								).format('YYYY/MM/DD')}
								compareValue={props.totalValues?.secondRange?.reduce(
									(acc, el) => acc + el.users,
									0
								)}
							/>
						</Col>

						<Col
							style={{ justifyContent: 'center', margin: '8px', display: 'flex' }}
							xs={24}
							sm={24}
							md={10}
							lg={5}
						>
							<TotalDataCard
								loading={loading}
								title={'Taux de rebond Moyen'}
								startDate={moment(
									new Date(props.multipleState.selection.startDate)
								).format('YYYY/MM/DD')}
								endDate={moment(
									new Date(props.multipleState.selection.endDate)
								).format('YYYY/MM/DD')}
								value={
									(
										props.totalValues?.firstRange?.reduce(
											(acc, el) => acc + el.bounceRate,
											0
										) / 2
									).toFixed(2) + ' %'
								}
								compare={
									props.multipleState.compare && props.totalValues?.secondRange
								}
								compareStartDate={moment(
									new Date(props.multipleState.compare?.startDate)
								).format('YYYY/MM/DD')}
								compareEndDate={moment(
									new Date(props.multipleState.compare?.endDate)
								).format('YYYY/MM/DD')}
								compareValue={
									(
										props.totalValues?.secondRange?.reduce(
											(acc, el) => acc + el.bounceRate,
											0
										) / 2
									).toFixed(2) + ' %'
								}
							/>
						</Col>
						<Col
							style={{ justifyContent: 'center', margin: '8px', display: 'flex' }}
							xs={24}
							sm={24}
							md={10}
							lg={5}
						>
							<Card
								loading={loading}
								bordered
								style={{
									borderWidth: '1px',
									borderRadius: '4%',
									padding: '16px 4px',
									minHeight: '200px',
									width: '100%',
									boxShadow:
										'0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 4px 8px 0 rgba(0, 0, 0, 0.15)',
								}}
							>
								<div style={{ textAlign: 'center' }}>
									<p>
										{moment(
											new Date(props.multipleState.selection.startDate)
										).format('YYYY/MM/DD')}{' '}
										-{' '}
										{moment(
											new Date(props.multipleState.selection.endDate)
										).format('YYYY/MM/DD')}
									</p>
									<div>
										<Title
											level={5}
											style={{
												display: 'inline-block',
												position: 'relative',
											}}
										>
											{props.totalValues?.firstRange[0]?._id}:{' '}
										</Title>
										<span
											style={{
												color: 'orange',
												fontFamily: 'sans-serif',
												fontSize: '18px',
											}}
										>
											{' '}
											{props.totalValues?.firstRange[0]?.users}
										</span>
									</div>
									<div>
										<Title
											level={5}
											style={{
												display: 'inline-block',
												position: 'relative',
											}}
										>
											{props.totalValues?.firstRange[1]?._id}:{' '}
										</Title>
										<span
											style={{
												color: 'orange',
												fontFamily: 'sans-serif',
												fontSize: '18px',
											}}
										>
											{' '}
											{props.totalValues?.firstRange[1]?.users}
										</span>
									</div>
								</div>
								<div style={{ textAlign: 'center' }}>
									{props.multipleState.compare &&
									props.totalValues?.secondRange ? (
										<p className='compare'>
											{moment(
												new Date(props.multipleState.compare.startDate)
											).format('YYYY/MM/DD') +
												' - ' +
												moment(
													new Date(props.multipleState.compare.endDate)
												).format('YYYY/MM/DD')}
										</p>
									) : (
										''
									)}
								</div>
								{props.totalValues?.secondRange && props.multipleState.compare && (
									<div style={{ textAlign: 'center' }}>
										<div>
											<Title
												level={5}
												style={{
													display: 'inline-block',
													position: 'relative',
												}}
											>
												{props.totalValues?.secondRange[0]._id}:{' '}
											</Title>
											<span
												style={{
													color: 'orange',
													fontFamily: 'sans-serif',
													fontSize: '18px',
												}}
											>
												{' '}
												{props.totalValues?.secondRange[0].users}
											</span>
										</div>
										<div>
											<Title
												level={5}
												style={{
													display: 'inline-block',
													position: 'relative',
												}}
											>
												{props.totalValues?.secondRange[1]._id}:{' '}
											</Title>
											<span
												style={{
													color: 'orange',
													fontFamily: 'sans-serif',
													fontSize: '18px',
												}}
											>
												{' '}
												{props.totalValues?.secondRange[1].users}
											</span>
										</div>
									</div>
								)}
							</Card>
						</Col>
					</Row>
				</div>
			</div>
		</>
	)
}

export default Bar
