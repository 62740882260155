import React, { useEffect, useState } from 'react'

import TrafficPeriod from './graphs/TrafficPeriod'

function Line(props) {
	const [show, setShow] = useState(null)
	const [data, setData] = useState(null)
	const [value, setValue] = React.useState('Users')
	const dataMassaging = () => {
		if (props.trafficPeriod?.hasOwnProperty('secondRange') && props.multipleState.compare) {
			setData(
				[...props.trafficPeriod.secondRange, ...props.trafficPeriod.firstRange].filter(
					(el) => el.id.split(' ')[0].toUpperCase() == value.split(' ')[0].toUpperCase()
				)
			)
			setShow(true)
		} else {
			setData(
				props.trafficPeriod?.firstRange.filter(
					(el) => el.id.split(' ')[0].toUpperCase() == value.split(' ')[0].toUpperCase()
				)
			)
		}
	}
	useEffect(() => {
		dataMassaging()
		console.log('topurl ', props.trafficPeriod)
	}, [props.trafficPeriod, props.multipleState.compare, props.trafficPeriod?.firstRange, value])

	const onChange = (e) => {
		console.log(props.trafficPeriod)
		console.log('radio checked', e.target.value)
		setValue(e.target.value)
	}

	return (
		<div style={{ width: '100%', paddingBottom: 20 }}>
			<h2>Variation du trafic par jour</h2>
			<div
				style={{
					display: show ? 'flex' : 'block',
					justifyContent: 'space-between',
				}}
			>
				{data && (
					<TrafficPeriod
						show={show}
						accounts={props.accounts}
						fetcher={props.fetcher}
						accountVal={props.accountVal}
						date={props.date}
						data={props.data}
						setLoader={props.setLoader}
						data={data}
						onChange={onChange}
						value={value}
					/>
				)}
			</div>
		</div>
	)
}

export default Line
