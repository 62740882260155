import React from 'react'
import { ResponsiveBar } from '@nivo/bar'
import { useEffect, useState } from 'react'
import { defaultDate, domain_from_url } from '../config'
import Layout, { Content } from 'antd/lib/layout/layout'

function Products(props) {
	const dateFormat = 'YYYY-MM-DD'
	const [loader, setLoader] = useState(null)
	const [account, setAccount] = useState(
		props.accounts.find((el) => domain_from_url(el.account) == 'modeco.tn')._id
	)
	const [data, setData] = useState([])
	const [height, setHeight] = useState(600)
	const [accountResponse, setAccountResponse] = useState(null)
	const [state, setState] = useState(defaultDate)
	const [pageNumber, setPageNumber] = useState(5)

	useEffect(() => {
		console.log(props.viewProducts)
		setData(props.viewProducts?.firstRange.data)
		setAccountResponse(
			props.accounts.find((el) => el._id == props.viewProducts?.firstRange.account)
		)
	}, [props.viewProducts])

	useEffect(() => {
		const legends = document.querySelectorAll(
			'#root > section > section > section:nth-child(1) > main > div > div:nth-child(5) > div > svg > g > g:nth-child(3) > g'
		)
		console.log(legends.length)
		legends.forEach((el) => {
			const text = el.querySelector('text')
			text.innerHTML = `<a href=${text.innerHTML} target="_blank" style="color:#1890ff">${text.innerHTML}</a>`
		})
	}, [data])

	return (
		<Layout className='site-layout'>
			<Content
				className='site-layout-background'
				style={{
					margin: '24px 16px 24px 16px',
					padding: 24,
				}}
			>
				<div style={{ height: height, width: '100%' }}>
					<h2>Comparatif vente par produit</h2>

					{props.viewProducts?.firstRange.data &&
						props.viewProducts.firstRange.data.length > 0 && (
							<ResponsiveBar
								onClick={(data) => {
									window.open(data.data.url, '_blank')
								}}
								tooltip={(el) => {
									return (
										<div>
											{el.data.url}: {el.value}{' '}
											{el.id == 'products' ? 'piece vendu' : ''}{' '}
											{el.id == 'views' ? 'vue' : ''}{' '}
											{el.id == 'availability' ? 'piece en stock' : ''}{' '}
											{el.id == 'budget' ? '$' : ''}
										</div>
									)
								}}
								// label={el => `${el.value} ${el.id=="products" ? "piece vendu" :""} ${el.id=="views" ? "vue" :""} ${el.id=="availability" ? "piece en stock" :""} ${el.id=="budget" ? "$" :""}`}
								data={data}
								keys={['products', 'views', 'availability', 'budget']}
								indexBy='url'
								margin={{ top: 50, right: 120, bottom: 50, left: 120 }}
								padding={0.3}
								groupMode='grouped'
								layout='horizontal'
								valueScale={{ type: 'linear' }}
								indexScale={{ type: 'band', round: true }}
								colors={{ scheme: 'category10' }}
								defs={[
									{
										id: 'dots',
										type: 'patternDots',
										background: 'inherit',
										color: '#38bcb2',
										size: 4,
										padding: 1,
										stagger: true,
									},
									{
										id: 'lines',
										type: 'patternLines',
										background: 'inherit',
										color: '#eed312',
										rotation: -45,
										lineWidth: 6,
										spacing: 10,
									},
								]}
								borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
								axisTop={null}
								axisRight={null}
								axisBottom={{
									tickSize: 5,
									tickPadding: 5,
									tickRotation: 0,
									legend: 'products',
									legendPosition: 'middle',
									legendOffset: 32,
								}}
								axisLeft={{
									tickSize: 5,
									tickPadding: 5,
									tickRotation: 0,
									legend: '',
									legendPosition: 'middle',
									legendOffset: -40,
								}}
								labelSkipWidth={12}
								labelSkipHeight={12}
								labelTextColor='#ffffff'
								legends={[
									{
										dataFrom: 'keys',
										anchor: 'top-right',
										direction: 'column',
										justify: false,
										translateX: 120,
										translateY: 0,
										itemsSpacing: 2,
										itemWidth: 100,
										itemHeight: 20,
										itemDirection: 'left-to-right',
										itemOpacity: 0.85,
										symbolSize: 20,
										effects: [
											{
												on: 'hover',
												style: {
													itemOpacity: 1,
												},
											},
										],
									},
								]}
								animate={true}
								motionStiffness={90}
								motionDamping={15}
							/>
						)}
				</div>
			</Content>
		</Layout>
	)
}

export default Products
