import React, { Component } from "react";
import { MapContainer, CircleMarker, TileLayer, Tooltip } from "react-leaflet";
import "leaflet/dist/leaflet.css";

let data = {
    city: [
      { name: "Tokyo", coordinates: [139.6917, 35.6895], population: 37843000 },
      { name: "Jakarta", coordinates: [106.865, -6.1751], population: 30539000 },
      { name: "Delhi", coordinates: [77.1025, 28.7041], population: 24998000 },
      { name: "Seoul", coordinates: [126.978, 37.5665], population: 23480000 },
      {
        name: "Shanghai",
        coordinates: [121.4737, 31.2304],
        population: 23416000
      },
      { name: "Karachi", coordinates: [67.0099, 24.8615], population: 22123000 },
      { name: "Beijing", coordinates: [116.4074, 39.9042], population: 21009000 },
      { name: "Mumbai", coordinates: [72.8777, 19.076], population: 17712000 },
      { name: "Osaka", coordinates: [135.5022, 34.6937], population: 17444000 },
      { name: "Moscow", coordinates: [37.6173, 55.7558], population: 16170000 },
      { name: "Dhaka", coordinates: [90.4125, 23.8103], population: 15669000 },
      { name: "Bangkok", coordinates: [100.5018, 13.7563], population: 14998000 },
      { name: "Kolkata", coordinates: [88.3639, 22.5726], population: 14667000 },
      { name: "newYork", coordinates: [-73.935242, 40.730610], population: 13287000 },
      { name: "tounes", coordinates: [9.5375, 33.8869], population: 13287000 },
    ],
    minLat: -6.1751,
    maxLat: 55.7558,
    minLong: 37.6173,
    maxLong: 139.6917
  };
  function normilize(val,  arr) { 
    const min = arr.sort((a,b)=> b.usersCount-a.usersCount)[arr.length-1].usersCount
    const max = arr.sort((a,b)=> b.usersCount-a.usersCount)[0].usersCount
    console.log("data values ",max, min)
    return Math.floor((((val - min) / (max - min))*40)+10) 
  }


class App extends Component {
    render() {
        console.log(this.props.data)
        var centerLat = (data.minLat + data.maxLat) / 2;
        var distanceLat = data.maxLat - data.minLat;
        var bufferLat = distanceLat * 0.05;
        var centerLong = (data.minLong + data.maxLong) / 2;
        var distanceLong = data.maxLong - data.minLong;
        var bufferLong = distanceLong * 0.05;
        return (
          <div>
            {/* <h3 style={{ textAlign: "center" }}>Most Populous Cities in Asia</h3> */}
            <MapContainer
              style={{ height: "480px", width: "100%", zIndex:1 }}
              zoom={3}
              center={[centerLat, centerLong]}
              bounds={[
                [data.minLat - bufferLat, data.minLong - bufferLong],
                [data.maxLat + bufferLat, data.maxLong + bufferLong]
              ]}
            >
              <TileLayer url="http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
    
              {this.props.data.filter(el=>el._id!="(not set)").map((city, k) => {
                return (
                  <CircleMarker
                    key={k}
                    center={[city["longitute"], city["latitude"]]}
                    radius={normilize(city["usersCount"], this.props.data)}
                    fillOpacity={0.5}
                    stroke={false}
                  >
                    <Tooltip direction="right" offset={[-8, -2]} opacity={1}>
                      <span>{city["_id"] + ": " + "users" + " " + city["usersCount"]}</span>
                    </Tooltip>
                  </CircleMarker>
                );
              })}
            </MapContainer>
          </div>
        );
    }
}

export default App;
