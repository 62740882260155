import React, { useEffect, useState } from 'react'
import Topurls from './graphs/Topurls'
import moment from 'moment'
var _ = require('lodash')

function TopUrlViews(props) {
	const [show, setShow] = useState(null)
	useEffect(() => {
		if (props.topUrlsViewsData?.hasOwnProperty('secondRange') && props.multipleState.compare) {
			setShow(true)
		} else {
			setShow(false)
		}
		console.log('topurl ', props.topUrlsViewsData)
	}, [props.topUrlsViewsData, props.multipleState.compare])
	return (
		<div style={{ width: '100%', margin: 'auto' }}>
			<h2>les pages les plus visitées</h2>
			<br />
			<div
				style={{
					display: show ? 'flex' : 'block',
					justifyContent: 'space-between',
					marginTop: 20,
				}}
			>
				<>
					<p
						style={{
							fontWeight: 'bold',
							textAlign: 'left',
							marginTop: 20,
							position: 'absolute',
							color: 'rgb(61, 145, 255)',
						}}
					>
						{moment(new Date(props.multipleState.selection.startDate)).format(
							'YYYY/MM/DD'
						)}{' '}
						-{' '}
						{moment(new Date(props.multipleState.selection.endDate)).format(
							'YYYY/MM/DD'
						)}
					</p>
					<Topurls show={show} topUrlsViewsData={props.topUrlsViewsData?.firstRange} />
				</>
				{show && props.multipleState.compare && (
					<>
						<p
							className='compare'
							style={{
								fontWeight: 'bold',
								position: 'absolute',
								marginTop: 20,
								left: '50%',
								color: 'rgb(62, 207, 142)',
							}}
						>
							{moment(new Date(props.multipleState.compare.startDate)).format(
								'YYYY/MM/DD'
							) +
								' - ' +
								moment(new Date(props.multipleState.compare.endDate)).format(
									'YYYY/MM/DD'
								)}
						</p>
						<Topurls
							show={show}
							topUrlsViewsData={props.topUrlsViewsData?.secondRange}
						/>
					</>
				)}
			</div>
		</div>
	)
}

export default TopUrlViews
