import { ResponsiveHeatMap } from '@nivo/heatmap'
import React, { useEffect, useState } from 'react'
import Layout, { Content } from 'antd/lib/layout/layout'

const days = {
	1: 'Mo',
	2: 'Tu',
	3: 'We',
	4: 'Th',
	5: 'Fr',
	6: 'Sa',
	7: 'Su',
}

function OrderSell(props) {
	const [data, setData] = useState([])

	const manupilateData = (payload) => {
		const dataVal = [
			{
				Day: 'Mo',
				0: 0,
				1: 0,
				2: 0,
				3: 0,
				4: 0,
				5: 0,
				6: 0,
				7: 0,
				8: 0,
				9: 0,
				10: 0,
				11: 0,
				12: 0,
				13: 0,
				14: 0,
				15: 0,
				16: 0,
				17: 0,
				18: 0,
				19: 0,
				20: 0,
				21: 0,
				22: 0,
				23: 0,
			},
			{
				Day: 'Tu',
				0: 0,
				1: 0,
				2: 0,
				3: 0,
				4: 0,
				5: 0,
				6: 0,
				7: 0,
				8: 0,
				9: 0,
				10: 0,
				11: 0,
				12: 0,
				13: 0,
				14: 0,
				15: 0,
				16: 0,
				17: 0,
				18: 0,
				19: 0,
				20: 0,
				21: 0,
				22: 0,
				23: 0,
			},
			{
				Day: 'We',
				0: 0,
				1: 0,
				2: 0,
				3: 0,
				4: 0,
				5: 0,
				6: 0,
				7: 0,
				8: 0,
				9: 0,
				10: 0,
				11: 0,
				12: 0,
				13: 0,
				14: 0,
				15: 0,
				16: 0,
				17: 0,
				18: 0,
				19: 0,
				20: 0,
				21: 0,
				22: 0,
				23: 0,
			},
			{
				Day: 'Th',
				0: 0,
				1: 0,
				2: 0,
				3: 0,
				4: 0,
				5: 0,
				6: 0,
				7: 0,
				8: 0,
				9: 0,
				10: 0,
				11: 0,
				12: 0,
				13: 0,
				14: 0,
				15: 0,
				16: 0,
				17: 0,
				18: 0,
				19: 0,
				20: 0,
				21: 0,
				22: 0,
				23: 0,
			},
			{
				Day: 'Fr',
				0: 0,
				1: 0,
				2: 0,
				3: 0,
				4: 0,
				5: 0,
				6: 0,
				7: 0,
				8: 0,
				9: 0,
				10: 0,
				11: 0,
				12: 0,
				13: 0,
				14: 0,
				15: 0,
				16: 0,
				17: 0,
				18: 0,
				19: 0,
				20: 0,
				21: 0,
				22: 0,
				23: 0,
			},
			{
				Day: 'Sa',
				0: 0,
				1: 0,
				2: 0,
				3: 0,
				4: 0,
				5: 0,
				6: 0,
				7: 0,
				8: 0,
				9: 0,
				10: 0,
				11: 0,
				12: 0,
				13: 0,
				14: 0,
				15: 0,
				16: 0,
				17: 0,
				18: 0,
				19: 0,
				20: 0,
				21: 0,
				22: 0,
				23: 0,
			},
			{
				Day: 'Su',
				0: 0,
				1: 0,
				2: 0,
				3: 0,
				4: 0,
				5: 0,
				6: 0,
				7: 0,
				8: 0,
				9: 0,
				10: 0,
				11: 0,
				12: 0,
				13: 0,
				14: 0,
				15: 0,
				16: 0,
				17: 0,
				18: 0,
				19: 0,
				20: 0,
				21: 0,
				22: 0,
				23: 0,
			},
		]

		dataVal.forEach((el) => {
			payload.forEach((Element) => {
				if (el['Day'] == days[String(Element['dayOfTheWeek'])]) {
					el[Element['hourOfTheWeek']] = Element['count']
				}
			})
		})

		setData(dataVal)
	}

	useEffect(() => {
		manupilateData(props.sellTime.firstRange)
	}, [props.sellTime.firstRange])

	useEffect(() => {
		window.addEventListener('DOMContentLoaded', () => {
			document
				.querySelectorAll(
					'#root > section > section > section:nth-child(5) > main > div > div > div > svg > g > g > text'
				)
				.forEach((el) =>
					props.sellTime.firstRange
						.sort((a, b) => b.count - a.count)
						.slice(0, 2)
						.map((Element) => Element.count)
						.includes(parseFloat(el.innerHTML))
						? (el.style.fill = '#fff !important')
						: ''
				)
		})
	}, [data])

	return (
		<Layout className='site-layout'>
			<Content
				className='site-layout-background'
				style={{
					margin: '24px 16px 24px 16px',
					padding: 24,
				}}
			>
				<h2>Horaires de vente sur la semaine</h2>

				<div style={{ height: 600, width: '100%' }}>
					<ResponsiveHeatMap
						data={data}
						keys={[
							'0',
							'1',
							'2',
							'3',
							'4',
							'5',
							'6',
							'7',
							'8',
							'9',
							'10',
							'11',
							'12',
							'13',
							'14',
							'15',
							'16',
							'17',
							'18',
							'19',
							'20',
							'21',
							'22',
							'23',
						]}
						indexBy='Day'
						margin={{ top: 100, right: 60, bottom: 60, left: 60 }}
						forceSquare={true}
						colors='blues'
						axisTop={{
							orient: 'top',
							tickSize: 5,
							tickPadding: 5,
							tickRotation: -90,
							legend: '',
							legendOffset: 36,
						}}
						axisRight={null}
						axisBottom={null}
						axisLeft={{
							orient: 'left',
							tickSize: 5,
							tickPadding: 5,
							tickRotation: 0,
							legend: 'country',
							legendPosition: 'middle',
							legendOffset: -40,
						}}
						cellOpacity={0.65}
						cellBorderColor={{ from: 'color', modifiers: [['darker', 0.4]] }}
						labelTextColor={{ from: 'color', modifiers: [['darker', '3']] }}
						defs={[
							{
								id: 'lines',
								type: 'patternLines',
								background: 'inherit',
								color: 'rgba(0, 0, 0, 0.1)',
								rotation: -45,
								lineWidth: 4,
								spacing: 7,
							},
						]}
						fill={[{ id: 'lines' }]}
						animate={true}
						motionConfig='wobbly'
						motionStiffness={80}
						motionDamping={9}
						hoverTarget='cell'
						cellHoverOthersOpacity={0.25}
					/>
				</div>
			</Content>
		</Layout>
	)
}

export default OrderSell
