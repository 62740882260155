import React, { useEffect, useState } from 'react'
import { ResponsiveBar } from '@nivo/bar'

import _ from 'loadsh'

function TopUrlViews(props) {
	const [height, setHeight] = useState(600)

	return (
		<div style={{ height: height, width: props.show ? '50%' : '100%', margin: 'auto' }}>
			{props.topUrlsViewsData && props.topUrlsViewsData.length > 0 && (
				<ResponsiveBar
					data={props.topUrlsViewsData}
					keys={_.flatten(
						props.topUrlsViewsData.map((el) =>
							Object.keys(el).filter((el) => el !== 'url')
						)
					)}
					indexBy='url'
					margin={{ top: 50, right: 100, bottom: 50, left: 100 }}
					padding={0.3}
					layout='vertical'
					valueScale={{ type: 'linear' }}
					indexScale={{ type: 'band', round: true }}
					colors={{ scheme: 'category10' }}
					tooltip={(point) => {
						return <div>{point.id}</div>
					}}
					label={(d) =>
						`${props.show ? '' : d.id.replace(/^.+[||]/, '') + ':'} ${d.value}`
					}
					defs={[
						{
							id: 'dots',
							type: 'patternDots',
							background: 'inherit',
							color: '#38bcb2',
							size: 4,
							padding: 1,
							stagger: true,
						},
						{
							id: 'lines',
							type: 'patternLines',
							background: 'inherit',
							color: '#eed312',
							rotation: -45,
							lineWidth: 6,
							spacing: 10,
						},
					]}
					borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
					axisTop={null}
					axisRight={null}
					axisBottom={{
						tickSize: 5,
						tickPadding: 5,
						tickRotation: 0,
						legend: 'traffics',
						legendPosition: 'middle',
						legendOffset: 32,
					}}
					axisLeft={{
						tickSize: 5,
						tickPadding: 5,
						tickRotation: 0,
						legend: '',
						legendPosition: 'middle',
						legendOffset: -70,
					}}
					labelSkipWidth={12}
					labelSkipHeight={12}
					labelTextColor='#ffffff'
					legends={[]}
					animate={true}
					motionStiffness={90}
					motionDamping={15}
				/>
			)}
		</div>
	)
}

export default TopUrlViews
