import { combineReducers } from 'redux'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import accountReducer from './account'

const rootReducer = combineReducers({
	accounts: accountReducer,
})

export default rootReducer
